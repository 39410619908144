import { Injectable } from '@angular/core';
import { CarsStore, createInitialState } from './cars.store';
import { CarsHttpService } from '../../../api/cars-http.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { MapService } from '../../map/state/map.service';
import {
  ICarGroupDTO,
  ICarPositionDTO,
  ICarPositionExtra,
} from 'src/app/api/models/dto/car-groups.dto';
import { CarsPositionStore } from '../../cars-position/cars-position.store';
import { CarsPositionQuery } from '../../cars-position/cars-position.query';
import { IFormValues } from 'src/app/pages/car-info-edit/car-edit-form/car-edit-form.component';
import { ICarConciseDTO, IPutCarConciseDTO } from 'src/app/api/models/dto/car-concise.dto';
import * as moment from 'moment';
import { CarRemindersHttpService } from 'src/app/api/car-reminders-http.service';
import { CarMovementHttpService } from 'src/app/api/car-movement-http.service';
import { CarsPositionService } from '../../cars-position/cars-position.service';
import { $ } from 'protractor';

@Injectable({ providedIn: 'root' })
export class CarsService {

  public constructor(
    private carsStore: CarsStore,
    private carsHttp: CarsHttpService,
    private carMovementHttp: CarMovementHttpService,
    private carReminderHttp: CarRemindersHttpService,
    private carsPositionQuery: CarsPositionQuery,
    private mapService: MapService,
    public carsPositionStore: CarsPositionStore,
    public carsPositionService: CarsPositionService
  ) { }

  public selectCar(carID: number): void {
    this.carsPositionStore.update((state) => {
      const index = state.selectedCarsIDs.indexOf(carID);
      let nextIDs: number[] = [];

      if (index >= 0) {
        nextIDs = [...state.selectedCarsIDs];
        nextIDs.splice(index, 1);
      } else {
        nextIDs = [...state.selectedCarsIDs, carID];
      }
      this.carsStore.update({changed : true});
      return {
        selectedCarsIDs: nextIDs
      };
    });

    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  public selectCars(selectedCarsIDs: number[]): void {
    this.carsPositionStore.update({ selectedCarsIDs });

    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  public selectStaticCars(selectedCarsStaticIDs: number[]): void {
    this.carsPositionStore.update({ selectedCarsStaticIDs });
  }

  public selectGroup(carGroup: ICarGroupDTO): void {
    this.carsPositionStore.update((state) => {
      const selectGroup = state.selectedCarsIDs.indexOf(carGroup.cars[0].carID) >= 0;
      let nextIDs: number[] = [];

      if (selectGroup) {
        nextIDs = [...state.selectedCarsIDs];
        carGroup.cars.forEach((car) => {
          const index = nextIDs.indexOf(car.carID);
          if (index >= 0) {
            nextIDs.splice(index, 1);
          }
        });
      } else {
        nextIDs = [...state.selectedCarsIDs];
        const ids = carGroup.cars.map((c) => c.carID);
        ids.forEach((id) => {
          const index = nextIDs.indexOf(id);
          if (index < 0) {
            nextIDs.push(id);
          }
        });
      }
      return {
        selectedCarsIDs: nextIDs
      };
    });

    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  public fetchGroupsWithCars(force = false): void {
    this.carsPositionStore.update((state) => {
      if (force || state.carPositions.length === 0) {
        of([])
          .pipe(
            tap(() => this.carsStore.update({ loading: true })),
            switchMap(() => this.carsHttp.fetchCarGroups()),
            tap((carGroups) => {
              this.carsStore.update({ carGroups: carGroups.carGroups, loading: false });
              this.fetchUpcomingReminders(carGroups.carGroups, this.onSucceded);
              const positions = [];
              for (const g of carGroups.carGroups) {
                for (const car of g.cars) {
                  positions.push({
                    carId: car.carID,
                    latitude: car.position.latitude,
                    longitude: car.position.longitude,
                    previousPositions: { latitude: car.position.latitude, longitude: car.position.longitude } as ICarPositionDTO,
                    lastKnownPositionAdress: car.lastKnownPositionAdress,
                    lastKnownDate: car.lastKnownDate,
                    driverName: car.lastKnownDriver,
                    direction: car.lastKnownDirection                   
                  } as ICarPositionExtra);
                }
              }
              this.carsPositionStore.update({ carPositions: positions });
            }),

            take(1)
          ).subscribe(result => { }, error => {
            this.carsStore.update({ loading: false });
          });
      }
      return { ...state };
    });
  }

  onSucceded = (carGroups: ICarGroupDTO[]): void => {
    this.fetchLastAddresses(carGroups);
}

  public updateCarConcise(carID: number, values: IFormValues): Observable<ICarConciseDTO> {
    const updatedCar = {
      carId: carID,
      fuelId: values.fuel,
      deviceId: values.serialNumber,
      carColorId: values.color,
      carBodyId: values.body,
      spz: values.plateNumber,
      entitySerialNumber: values.vin,
      note: values.note,
      toolTipText: values.description,
      ccsCardNumber: values.ccsCardNumber,
      isDozorEnabled: values.supervision,
      maxSpeed: values.maxSpeed,
    } as IPutCarConciseDTO;

    return this.carsHttp.updateCar(carID, updatedCar);
  }

  public fetchLastAddresses(carGroups: ICarGroupDTO[]): void {
    if (!!carGroups && carGroups.length !== 0) {
      const carIds: number[] = [];
      for (const cargroup of carGroups) {
        for (const car of cargroup.cars) {
          carIds.push(car.carID);
        }
      }
      this.carsHttp.fetchLastAddresses(carIds).subscribe((positions) => {
        const updatedCarGroups = [];
        for (const cargroup of carGroups) {
          const newCarGroup = { ...cargroup, cars: [] };
          for (const car of cargroup.cars) {
            const positionDTO = positions.carAddresses.find((position) => position.carId === car.carID);
            const timeOfDrive = moment.utc(positionDTO.timeOfDrive).local();
            newCarGroup.cars.push({
              ...car, lastKnownPositionAdress: positionDTO.address,
              lastKnownDate: timeOfDrive
            });
          }
          updatedCarGroups.push(newCarGroup);
        }
        this.carsStore.update({ carGroups: updatedCarGroups });
      });
    }
  }

  public fetchUpcomingReminders(carGroups: ICarGroupDTO[], onSuceeded: (carGroups: ICarGroupDTO[]) => void): void {
    if (carGroups.length !== 0) {
      for (const cargroup of carGroups) {
        for (const car of cargroup.cars) {
          if (!!car.reminders && car.reminders.length > 0) {
            this.carReminderHttp.fetchUpcomingRemindersByCarID(car.carID).subscribe(reminders => {
              car.activeReminders = reminders.reminders; //PRX tohle hgeldas na opravu upominek
            });
          }
        }
      }
    }
    onSuceeded(carGroups);
  }

  public allowCarMovement(carID: number): Observable<any> {
    return this.carMovementHttp.allowCarMovement(carID);
  }

  public forbidCarMovement(carID: number): Observable<any> {
    return this.carMovementHttp.forbidCarMovement(carID);
  }

  public clearSelectedCarIDs(): void {
    this.carsPositionStore.update({ selectedCarsIDs: [] });
  }

  public clearIdExceptOne(objectId: number): void {
    this.carsPositionStore.update((state) => ({
      ...state,
      selectedCarsIDs: state.selectedCarsIDs.filter(p => p === objectId)
    }));
  }

  public clearStore(): void {
    this.carsStore.update(createInitialState());
  }

  public getOnlineCarImage(carID: number, course: number, status: number, selected: number): string{
    let cr = course / 10;
    cr += 90;
    while(cr >= 360) cr = cr - 360;
    while(cr < 0) cr = 360 + cr;
    cr = Math.round(cr);
    console.log('course ' + course + 'cr ' + cr);

    //return 'assets/images/CarImages/carImage' + cr + '_' + status + '_' + selected + '.png';
    var rootUrl = '../../../assets/images/CarImages/';

    var iconCar = '';
    var rotate = false;
    if (status == 1)
    {
        iconCar = selected == 1 ? "onlineCarArrow-Selected_"+cr+".png" : "onlineCarArrow_"+cr+".png";
        rotate = true;
    }
    else if (status == 0)
    {
        iconCar = selected == 1 ? "onlineCarArrowAvailable-Selected_"+cr+".png" : "onlineCarArrowAvailable_"+cr+".png";
        rotate = true;
    }
    else if (status == 3)
    {
        iconCar = selected == 1 ? "onlineCarArrowDisabled-Selected.png" : "onlineCarArrowDisabled.png";
    }
    else if (status == 2)
    {
        iconCar = selected == 1
            ? "onlineCarArrowDisabledAvailable-Selected_"+cr+".png"
            : "onlineCarArrowDisabledAvailable.png";
    }
    else if (status == 5)
    {
        iconCar = selected == 1 ? "onlineCarArrowStop-Selected.png" : "onlineCarArrowStop.png";
    }
    else if (status == 4)
    {
        iconCar = selected == 1
            ? "onlineCarArrowStopAvailable-Selected.png"
            : "onlineCarArrowStopAvailable.png";
    }
    else{
      iconCar = selected == 1 ? "onlineCarArrowDisabled-Selected.png" : "onlineCarArrowDisabled.png";
    }

    return rootUrl + iconCar;
  }
}

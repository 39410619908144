import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ICarDrivePrivateSchedulerStore, ICarDrivePrivateSchedulerState } from './car-drive-private-scheduler.store';

@Injectable({ providedIn: 'root' })
export class ICarDrivePrivateSchedulerQuery extends Query<ICarDrivePrivateSchedulerState> {

  public isLoading$ = this.select((state) => state.loading);

  public carDrivePrivateScheduler$ = this.select((state) => state.carDrivePrivateScheduler);

  public constructor(protected store: ICarDrivePrivateSchedulerStore) {
    super(store);
  }
}

<app-page-wrapper [isLoading]="loadingData || car === null"
  [hasSidePadding]="false">
  <ng-template appPageContent
    *ngxPermissionsOnly="['car-detail']">
    <div class="content">
      <div class="main-details">
        <ui-divider [compact]="true"></ui-divider>
        <ng-container *ngFor="let item of items">
          <app-car-detail-item [label]="item?.key | translate"
            [value]="item?.value"
            [translateValue]="item?.isTranslated"
            [icon]="item?.icon"
            [divider]="item?.divider">
          </app-car-detail-item>
        </ng-container>
      </div>
      <ng-container *ngxPermissionsOnly="['car-edit']">
          <mat-icon matTooltip="{{ 'car-edit' | translate }}" (click)="navigateToCarEdit()" class="edit draw-icon transparent">edit</mat-icon>
      </ng-container>
    </div>
    <ui-divider></ui-divider>
    <ui-divider></ui-divider>
    <mat-tab-group>
      <mat-tab [label]="'fueling.fueling' | translate">
        <ui-divider></ui-divider>
        <app-fueling [carId]="car?.carId"> </app-fueling>
      </mat-tab>
      <ng-container *ngxPermissionsOnly="['car-guardian']">
        <mat-tab [label]="'guardian.title' | translate">
          <app-car-guardian [carId]="car?.carId"> </app-car-guardian>
        </mat-tab>
      </ng-container>
      <mat-tab [label]="'drivePrivateScheduler.title' | translate">
        <app-car-drive-private-scheduler [carId]="car?.carId"> </app-car-drive-private-scheduler>
      </mat-tab>
      <ng-container *ngxPermissionsOnly="['reminder-list']">
        <mat-tab [label]="'car-detail.reminders' | translate">
          <ui-divider></ui-divider>
          <div id="reminderList">
            <app-reminders-list [reminders]="carRemindersQuery.reminders$ | async"
            [detailCarID]="car?.carId" (isEdited)="isEddited($event)">
            </app-reminders-list>
            <ui-divider></ui-divider>
          </div>
          <div id="reminderOperation">
            <div *ngIf="carRemindersQuery.isNewReminder$ | async" class="reminder-operation">
              <div class="title-container">
                <div class="reminder-name-car">
                  <div class="title">{{ 'remider.page.selectedcar' | translate }} </div>
                  <div class="title-content">{{car?.spz}} {{car?.toolTipText}} </div>           
                </div>
                <div class="reminder-name-car">
                  <div class="title">{{ 'remider.page.selectedcar-tacho' | translate }} </div>
                  <div class="title-content">{{lastTachoValue}} </div>          
                </div>
              </div>
              <ui-divider></ui-divider>
              <app-reminders-operation [carID]="carRemindersQuery.carID$ | async" (isEdited)="isEddited($event)">
              </app-reminders-operation>
              <ui-divider></ui-divider>
          </div>
          </div>
        </mat-tab>
        <ng-container *ngxPermissionsOnly="['car-area-control']">
        <mat-tab [label]="'car-detail.area-control' | translate">
          <ui-divider></ui-divider>
          <app-area-control-tab [carId]="car?.carId">       
          </app-area-control-tab>
          <ui-divider></ui-divider>
        </mat-tab>
      </ng-container>
      <ng-container *ngxPermissionsOnly="['export-report']">
        <mat-tab [label]="'car-detail.report' | translate">
          <ui-divider></ui-divider>
          <app-export-schedule [carId]="car?.carId">            
          </app-export-schedule>
          <ui-divider></ui-divider>
        </mat-tab>
      </ng-container>
      </ng-container>
    </mat-tab-group>
  </ng-template>
</app-page-wrapper>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlsService } from './urls.service';
import { ICarDrivePrivateSchedulerDTO, ICarDrivePrivateScheduler } from './models/dto/car-drive-private-scheduler.dto';

@Injectable()
export class CarDrivePrivateSchedulerHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchCarDrivePrivateScheduler(carID: number): Observable<ICarDrivePrivateSchedulerDTO> {
    return this.http.get<ICarDrivePrivateSchedulerDTO>(this.urls.fetchCarDrivePrivateScheduler(carID));
  }

  public createCarDrivePrivateScheduler(carID: number, schedule: ICarDrivePrivateScheduler): Observable<ICarDrivePrivateSchedulerDTO> {
    return this.http.post<ICarDrivePrivateSchedulerDTO>(this.urls.createCarDrivePrivateScheduler(carID), schedule);
  }

  public updateCarDrivePrivateScheduler(carDrivePrivateSchedulerID: number, schedule: ICarDrivePrivateScheduler): Observable<ICarDrivePrivateSchedulerDTO> {
    return this.http.put<ICarDrivePrivateSchedulerDTO>(this.urls.updateCarDrivePrivateScheduler(carDrivePrivateSchedulerID), schedule);
  }
}

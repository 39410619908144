import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarInfoEditPageComponent } from './car-info-edit/car-info-edit-page/car-info-edit-page.component';
import { CustomMaterialModule } from '../material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiModule } from '../api/api.module';
import { TranslateModule } from '@ngx-translate/core';
import { CarEditFormComponent } from './car-info-edit/car-edit-form/car-edit-form.component';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { CarsPageComponent } from './cars/cars-page/cars-page.component';
import { CarGroupRowComponent } from './cars/car-group-row/car-group-row.component';
import { CarRowComponent } from './cars/car-row/car-row.component';
import { CarActionItemComponent } from './cars/car-action-item/car-action-item.component';
import { CarDetailPageComponent } from './car-detail/car-detail-page/car-detail-page.component';
import { CarDetailItemComponent } from './car-detail/car-detail-item/car-detail-item.component';
import { RouterModule } from '@angular/router';
import { DriveBookPageComponent } from './drive-book/drive-book-page/drive-book-page.component';
import { FilterComponent } from './drive-book/filter/filter.component';
import { TotalsComponent } from './drive-book/totals/totals.component';
import { DriveBookListComponent } from './drive-book/drive-book-list/drive-book-list.component';
import { CarInfoComponent } from './drive-book/car-info/car-info.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomFilterComponent } from './drive-book/custom-filter/custom-filter.component';
import { DriveBookDetailPageComponent } from './drive-book-detail/drive-book-detail-page/drive-book-detail-page.component';
import { EditFormComponent as DriveBookEditFormComponent } from './drive-book-detail/edit-form/edit-form.component';
import { SpeedChartComponent } from './drive-book/speed-chart/speed-chart.component';
import { ChartsModule } from 'ng2-charts';
import { CarRemindersPageComponent } from './car-reminders/car-reminders-page/car-reminders-page.component';
import { RemindersListComponent } from './car-reminders/reminders-list/reminders-list.component';
import { StatsPageComponent } from './stats/stats-page/stats-page.component';
import { StatsDatePickerComponent } from './stats/stats-date-picker/stats-date-picker.component';
import { StatsCalendarDayComponent } from './stats/stats-calendar-day/stats-calendar-day.component';
import { ExportPageComponent } from './export-page/export-page.component';
import { FuelCardPageComponent } from './fuel-card/fuel-card-page.component';
import { DriveBookAddPageComponent } from './drive-book-add/drive-book-add-page.component';
import { AreaControlPageComponent } from './area-control-page/area-control-page.component';
import { AreaControlAddPageComponent } from './area-control-add/page/area-control-add-page.component';
import { AreaControlEditPageComponent } from './area-control-edit/page/area-control-edit-page.component';
import { ReminderOperationComponent } from './car-reminders/car-reminder-operation/car-reminder-operation.component';
import { ReminderConditionsListComponent } from './car-reminders/car-reminder-conditions-list/car-reminder-conditions-list.component';
import { ReminderDialogBoxComponent } from './car-reminders/reminder-dialog-box/reminder-dialog-box.component';
import { RemindersListDialogComponent } from './car-reminders/reminder-list-confirm-delete/reminders-list-dialog.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { ProfileContactDetailsComponent } from '../components/profile/contact-details/profile-contact-details.component';
import { ProfileChangePasswordComponent } from '../components/profile/change-password/profile-change-password.component';
import { AlertIconComponent } from './cars/alert-icon/alert-icon.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AreaControlDetailComponent } from './area-control-detail/area-control-detail.component';
import { AreaControVehicleEditDialogComponent } from './area-control-vehicle-edit-dialog/area-control-vehicle-edit-dialog.component';
import { FuelingComponent } from './car-detail/fueling/fueling.component';
import { CarGuardianComponent } from './car-detail/car-guardian/car-guardian.component';
import { CarDrivePrivateSchedulerComponent } from './car-detail/car-drive-private-scheduler/car-drive-private-scheduler.component';
import { AreaControlListComponent } from './area-control-list/area-control-list.component';
import { ExportScheduleComponent } from './car-detail/export-schedule/export-schedule.component';
import { ExportScheduleEditDialogComponent } from './export-page/export-schedule-edit-dialog/export-schedule-edit-dialog.component';
import { AreaControlTabComponent } from './car-detail/area-control-tab/area-control-tab.component';
import { CarMovementEditDialogComponent } from 'src/app/pages/car-movement-dialog/car-movement-edit-dialog.component';
import { DriveBookEditDialogComponent } from './drive-book/drive-book-edit-dialog/drive-book-edit-dialog.component';
import { DriveBookLockDialogComponent } from './drive-book/drive-book-lock-dialog/drive-book-lock-dialog.component';

const pages = [
  CarInfoEditPageComponent,
  CarsPageComponent,
  CarDetailPageComponent,
  DriveBookPageComponent,
  DriveBookDetailPageComponent,
  CarRemindersPageComponent,
  FuelCardPageComponent,
  ExportPageComponent,
  AreaControlPageComponent,
  AreaControlAddPageComponent,
  AreaControlEditPageComponent,
  DriveBookAddPageComponent,
  StatsPageComponent,
  CarRemindersPageComponent,
  DriveBookAddPageComponent,
  ProfileComponent,
  ProfileContactDetailsComponent,
  ProfileChangePasswordComponent,
  AreaControlDetailComponent,
];

@NgModule({
  declarations: [
    ...pages,
    CarEditFormComponent,
    CarGroupRowComponent,
    CarRowComponent,
    CarActionItemComponent,
    CarDetailItemComponent,
    FilterComponent,
    TotalsComponent,
    DriveBookListComponent,
    CarInfoComponent,
    CustomFilterComponent,
    DriveBookEditFormComponent,
    SpeedChartComponent,
    RemindersListComponent,
    RemindersListComponent,
    StatsDatePickerComponent,
    StatsCalendarDayComponent,
    RemindersListComponent,
    ReminderOperationComponent,
    ReminderConditionsListComponent,
    RemindersListDialogComponent,
    ReminderDialogBoxComponent,
    AlertIconComponent,
    AreaControVehicleEditDialogComponent,
    FuelingComponent,
    CarGuardianComponent,
    CarDrivePrivateSchedulerComponent,
    AreaControlListComponent,
    ExportScheduleComponent,
    ExportScheduleEditDialogComponent,
    AreaControlTabComponent,
    CarMovementEditDialogComponent,
    DriveBookEditDialogComponent,
    DriveBookLockDialogComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    ApiModule,
    TranslateModule,
    UiComponentsModule,
    RouterModule,
    ChartsModule,
    FormsModule,
    NgxPermissionsModule
  ],
  exports: [
    ...pages,
    UiComponentsModule
  ],
  entryComponents: [
    ReminderDialogBoxComponent,
    RemindersListDialogComponent,
    AreaControVehicleEditDialogComponent,
    ProfileComponent,
    ExportScheduleEditDialogComponent,
    DriveBookEditDialogComponent,
    DriveBookLockDialogComponent
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'cs'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class PagesModule { }

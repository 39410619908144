import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICarColorDTO } from '../../../api/models/dto/car-colors.dto';
import { ICarConciseDTO } from '../../../api/models/dto/car-concise.dto';
import { ICarFuelDTO } from '../../../api/models/dto/car-fuels.dto';
import { ICarBodyDTO } from 'src/app/api/models/dto/car-body.dto';
import { ConfirmDialogService } from 'src/app/ui-components/services/confirm-dialog.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

const CANCEL_MSG = 'car-edit-form.confirm.cancel';
export interface IFormValues {
  serialNumber: number;
  plateNumber: string;
  description: string;
  vin: string;
  supervision: boolean;
  ccsCardNumber: string;
  note: string;
  color: number;
  fuel: number;
  body: number;
  maxSpeed: number;
}

@Component({
  selector: 'app-car-edit-form',
  templateUrl: './car-edit-form.component.html',
  styleUrls: ['./car-edit-form.component.scss']
})
export class CarEditFormComponent implements OnInit, OnDestroy {

  @Output()
  public submitAction = new EventEmitter<IFormValues>();

  @Output()
  public cancelAction = new EventEmitter<boolean>();

  @Output()
  public cancelForm = new EventEmitter<void>();

  @Input()
  public car: ICarConciseDTO;

  @Input()
  public colors: ICarColorDTO[];

  @Input()
  public fuels: ICarFuelDTO[];

  @Input()
  public bodies: ICarBodyDTO[];

  public form: FormGroup;

  public formInitialized = false;

  private subs = new Subscription();

  public constructor(
    private fb: FormBuilder,
    private confirmDialog: ConfirmDialogService,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public ngOnInit(): void {
    this.form = this.initializeForm();
    this.formInitialized = true;
  }

  private initializeForm(): FormGroup {
    return this.fb.group({
      serialNumber: [
        this.car.deviceId
      ],
      plateNumber: [
        this.car.spz,
        [Validators.required, Validators.maxLength(20)]
      ],
      description: [
        this.car.toolTipText,
        [Validators.required, Validators.maxLength(200)]
      ],
      vin: [
        this.car.entitySerialNumber,
        [Validators.maxLength(200)]
      ],
      ccsCardNumber: [
        this.car.ccsCardNumber,
        [Validators.maxLength(30)]
      ],
      note: [
        this.car.note,
        [Validators.maxLength(2000)]
      ],
      color: [
        !!this.car?.carColor ? this.car?.carColor?.nCarColorID : 0
      ],
      fuel: [
        !!this.car?.fuel ? this.car?.fuel?.nFuelID : 0
      ],
      body: [
         !!this.car?.carBody ? this.car?.carBody?.nCarBodyID : 0
      ],
      maxSpeed: [
        this.car.maxSpeed,
        [Validators.min(0), Validators.max(500)]
      ]
    });
  }

  public handleSubmit(event: Event): void {
    const values = this.form.value as IFormValues;
    const spz = values.plateNumber;
    const desc = values.description;

    if (!spz || !desc) {
      this.notifyService.showError(this.translateService.instant('drive-book.edit-form.error.invalid-input'));
      return;
    }

    this.submitAction.emit(this.form.value as IFormValues);
  }

  public handleCancel(): void {
    if (this.form.dirty) {
      this.subs.add(
        this.confirmDialog
          .open(CANCEL_MSG)
          .subscribe(() => this.cancelAction.emit(true))
      );
    } else {
      this.cancelAction.emit(true);
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

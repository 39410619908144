import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ICarDrivePrivateSchedulerDTO } from 'src/app/api/models/dto/car-drive-private-scheduler.dto';

export interface ICarDrivePrivateSchedulerState {
   carDrivePrivateScheduler?: ICarDrivePrivateSchedulerDTO;
   loading?: boolean;
}

export function createInitialState(): ICarDrivePrivateSchedulerState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'car-drive-private-scheduler' })
export class ICarDrivePrivateSchedulerStore extends Store<ICarDrivePrivateSchedulerState> {

  public constructor() {
    super(createInitialState());
  }

}

import { Injectable } from '@angular/core';
import { ICarDrivePrivateSchedulerStore } from './car-drive-private-scheduler.store';
import { CarDrivePrivateSchedulerHttpService } from 'src/app/api/car-drive-private-scheduler-http.service';
import { ICarDrivePrivateScheduler } from 'src/app/api/models/dto/car-drive-private-scheduler.dto';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CarDrivePrivateSchedulerService {

  public constructor(
    public carDrivePrivateSchedulerStore: ICarDrivePrivateSchedulerStore,
    private carDrivePrivateSchedulerHttp: CarDrivePrivateSchedulerHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public setIsLoading(loading: boolean): void {
    this.carDrivePrivateSchedulerStore.update({ loading });
  }

  public fetchCarDrivePrivateScheduler(carId: number) {
    this.setIsLoading(true);
    this.carDrivePrivateSchedulerHttp.fetchCarDrivePrivateScheduler(carId).subscribe(carDrivePrivateScheduler => {
      this.carDrivePrivateSchedulerStore.update({ carDrivePrivateScheduler: carDrivePrivateScheduler });
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public clearStore(): void {
    this.carDrivePrivateSchedulerStore.update({
      carDrivePrivateScheduler: null,
      loading: false
    });
  }

  public createSchedule(carID: number, schedule: ICarDrivePrivateScheduler, callback: () => void) {
    this.setIsLoading(true);
    this.carDrivePrivateSchedulerHttp.createCarDrivePrivateScheduler(carID, schedule).subscribe(x => {
      this.notifyService.showInfo(this.translateService.instant('drivePrivateScheduler.saved'));
      this.setIsLoading(false);
      this.fetchCarDrivePrivateScheduler(carID);
      callback();
    }, error => {
      if (error.error.errors.PhoneNumber) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      } else {
        this.setIsLoading(false);
        this.notifyService.showError(this.translateService.instant('drivePrivateScheduler.not-saved'));
      }
    });
  }

  public updateSchedule(carId: number, drivePrivateSchedulerId: number, schedule: ICarDrivePrivateScheduler, callback: () => void) {
    this.setIsLoading(true);
    this.carDrivePrivateSchedulerHttp.updateCarDrivePrivateScheduler(drivePrivateSchedulerId, schedule).subscribe(x => {
      this.notifyService.showInfo(this.translateService.instant('drivePrivateScheduler.saved'));
      this.setIsLoading(false);
      this.fetchCarDrivePrivateScheduler(carId);
      callback();
    }, error => {
      if (error.error.errors.PhoneNumber) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      } else {
        this.setIsLoading(false);
        this.notifyService.showError(this.translateService.instant('drivePrivateScheduler.not-saved'));
      }
    });
  }

  public initArray(days, hours): any {
    const array = [];
    for (let d = 0; d < days?.length; d++) {
      array.push([]);
      for (const h of hours) {
        array[d].push(false);
      }
    }
    return array;
  }
}

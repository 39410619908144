import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ICarDrivePrivateScheduler } from 'src/app/api/models/dto/car-drive-private-scheduler.dto';
import { WeekDay } from 'src/app/api/models/dto/guardian.dto';
import { CarDrivePrivateSchedulerService } from 'src/app/store/car-drive-private-scheduler/car-drive-private-scheduler.service';
import { ICarDrivePrivateSchedulerQuery } from 'src/app/store/car-drive-private-scheduler/car-drive-private-scheduler.query';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CarDrivePrivateSchedulerHttpService } from 'src/app/api/car-drive-private-scheduler-http.service';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-car-drive-private-scheduler',
  templateUrl: './car-drive-private-scheduler.component.html',
  styleUrls: ['./car-drive-private-scheduler.component.scss']
})
export class CarDrivePrivateSchedulerComponent implements OnInit, OnDestroy {
  @Input() carId: number;

  carDrivePrivateSchedulerID: number;
  selected: boolean[][];
  halfSelected: boolean[][];
  start: any;
  carDrivePrivateScheduler: ICarDrivePrivateScheduler;
  hours: string[] = Array.from(Array(24).keys()).map(x => x.toString());
  days: WeekDay[] = [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday,
  WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday];
  private subs = new Subscription();
  isEditMode = false;

  constructor(
    public carDrivePrivateSchedulerQuery: ICarDrivePrivateSchedulerQuery,
    public carDrivePrivateSchedulerService: CarDrivePrivateSchedulerService,
    public carDrivePrivateSchedulerHttp: CarDrivePrivateSchedulerHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
    private utilitiesService: UtilitiesService) {
    this.subs.add(this.carDrivePrivateSchedulerQuery.carDrivePrivateScheduler$.subscribe(carDrivePrivateScheduler => {
      this.carDrivePrivateSchedulerID = carDrivePrivateScheduler?.drivePrivateSchedulerId;
      this.carDrivePrivateScheduler = carDrivePrivateScheduler;
      if (!carDrivePrivateScheduler?.schedule || carDrivePrivateScheduler?.schedule?.length === 0) {
        this.selected = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
      } else {
        this.selected = carDrivePrivateScheduler?.schedule;
      }
    }));
  }

  ngOnInit(): void {
    this.carDrivePrivateSchedulerService.fetchCarDrivePrivateScheduler(this.carId);
    this.halfSelected = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
  }

  onStartPicked(day: number, hour: number): void {
    this.start = {
      day,
      hour
    };
  }

  onEndPicked(day: number, hour: number): void {
    if (this.start.day === day && this.start.hour === hour) {
      this.selected[day][hour] = !this.selected[day][hour];
    }
    this.start = null;
    this.selected = this.mergeArrays({ ...this.halfSelected }, { ...this.selected });
    this.halfSelected = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
  }

  onMouseOver(day: number, hour: number): void {
    if (!!this.start) {
      const dayFrom = Math.min(this.start.day, day);
      const dayTo = Math.max(this.start.day, day);
      const hourFrom = Math.min(this.start.hour, hour);
      const hourTo = Math.max(this.start.hour, hour);
      this.halfSelected = this.fillSelectionArea(dayFrom, dayTo, hourFrom, hourTo);
    }
  }

  fillSelectionArea(dayFrom: number, dayTo: number, hourFrom: number, hourTo: number): any {
    const filledArray = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
    for (let d = 0; d < this.days?.length; d++) {
      for (let h = 0; h < this.hours?.length; h++) {
        if (dayFrom <= d && d <= dayTo && hourFrom <= h && h <= hourTo) {
          filledArray[d][h] = true;
        }
      }
    }
    return filledArray;
  }

  mergeArrays(halfSelected: any, selected: any): any {
    let allFilled = true;
    for (let d = 0; d < this.days?.length; d++) {
      for (let h = 0; h < this.hours?.length; h++) {
        if (halfSelected[d][h]) {
          allFilled = allFilled && selected[d][h];
        }
      }
    }

    const merged = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
    for (let d = 0; d < this.days?.length; d++) {
      for (let h = 0; h < this.hours?.length; h++) {
        if (halfSelected[d][h]) {
          merged[d][h] = allFilled ? false : true;
        } else {
          merged[d][h] = selected[d][h];
        }
      }
    }
    return merged;
  }

  selectHour(hour: number): void {
    let areAllSelected = true;
    for (let d = 0; d < this.days?.length; d++) {
      if (this.selected[d][hour] === false) {
        areAllSelected = false;
      }
    }
    for (let d = 0; d < this.days?.length; d++) {
      this.selected[d][hour] = !areAllSelected;
    }
  }

  selectDay(day: number): void {
    let areAllSelected = true;
    for (let h = 0; h < this.hours?.length; h++) {
      if (this.selected[day][h] === false) {
        areAllSelected = false;
      }
    }
    for (let h = 0; h < this.hours?.length; h++) {
      this.selected[day][h] = !areAllSelected;
    }
  }

  setSchedule() {
    this.carDrivePrivateScheduler.schedule = this.selected;
    if (!this.carDrivePrivateSchedulerID) {
      this.carDrivePrivateSchedulerService.createSchedule(this.carId, this.carDrivePrivateScheduler, () => {
        this.isEditMode = false;
      });
    } else {
        this.carDrivePrivateSchedulerService.updateSchedule(this.carId, this.carDrivePrivateSchedulerID, this.carDrivePrivateScheduler, () => {
          this.isEditMode = false;
        });
    }
  }

  enableCarDrivePrivateScheduler(active: boolean): void {
    this.carDrivePrivateScheduler.active = active;
    if (!this.carDrivePrivateSchedulerID) {
      this.isEditMode = true;
      this.carDrivePrivateScheduler.schedule = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
    } else {
      if (this.carDrivePrivateScheduler.active) {
        this.carDrivePrivateScheduler.schedule = this.selected;
      }
      this.carDrivePrivateSchedulerService.updateSchedule(this.carId, this.carDrivePrivateSchedulerID, this.carDrivePrivateScheduler, () => {
        this.isEditMode = false;
      });
    }
  }

  setEditMode(value: boolean): void {
    this.isEditMode = value;
    if (!this.isEditMode) {
      this.carDrivePrivateSchedulerHttp.fetchCarDrivePrivateScheduler(this.carId).subscribe(carDrivePrivateScheduler => {
        this.carDrivePrivateScheduler = carDrivePrivateScheduler;
        this.halfSelected = this.carDrivePrivateSchedulerService.initArray(this.days, this.hours);
        this.selected = this.carDrivePrivateScheduler.schedule;
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

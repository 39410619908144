<ui-divider></ui-divider>
<ng-container *ngIf="carDrivePrivateScheduler?.active">
  <div class="padded-content">
    <ng-container *ngxPermissionsOnly="['car-edit']">
      <ng-container *ngIf="!!selected">
        <p *ngIf="isEditMode" class="label">{{ 'drivePrivateScheduler.info' | translate }}</p>
        <ui-divider *ngIf="!isEditMode" [large]="true"></ui-divider>
        <ui-divider *ngIf="!isEditMode" [large]="true"></ui-divider>
        <ui-divider *ngIf="!isEditMode"></ui-divider>
        <div class="day">
          <div class="day-header">
          </div>
          <ng-container *ngFor="let hour of hours; index as h">
            <div class="hour"
              *ngIf="isEditMode"
              (click)="selectHour(h)">
              {{hour}}
            </div>
            <div class="hour disabled-field"
              *ngIf="!isEditMode">
              {{hour}}
            </div>
          </ng-container>
        </div>
        <ng-container *ngFor="let day of days; index as d">
          <div class="day">
            <div class="day-header"
              *ngIf="isEditMode"
              (click)="selectDay(d)">
              {{ day | weekday }}
            </div>
            <div class="day-header disabled-field"
              *ngIf="!isEditMode">
              {{ day | weekday }}
            </div>
            <ng-container *ngFor="let hour of hours; index as h">
              <div class="hour"
                *ngIf="isEditMode"
                (mousedown)="onStartPicked(d, h)"
                (mouseup)="onEndPicked(d, h)"
                (mouseover)="onMouseOver(d, h)">
                <div class="box"
                  [ngClass]="{'selected': selected[d][h] === true, 'half-selected': halfSelected[d][h] === true}">
                </div>
              </div>
              <div class="hour disabled-field"
                *ngIf="!isEditMode">
                <div class="box"
                  [ngClass]="{'selected': selected[d][h] === true}">
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ui-divider></ui-divider>
        <ng-container *ngxPermissionsOnly="['car-edit']">
          <div class="row end"
            *ngIf="!isEditMode">
            <button mat-button
              mat-raised-button
              color="primary"
              (click)="enableCarDrivePrivateScheduler(false)"
              class='save-button'>
              {{ 'drivePrivateScheduler.disable' | translate }}
            </button>
            <button mat-button
              mat-raised-button
              color="primary"
              (click)=" setEditMode(true)"
              class='save-button'>
              {{ 'form-actions.edit' | translate }}
            </button>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          </div>
          <div class="row end"
            *ngIf="isEditMode">
            <button mat-button
              (click)="setEditMode(false)"
              class='save-button'>
              {{ 'form-actions.cancel' | translate }}
            </button>
            <button mat-button
              mat-raised-button
              color="primary"
              (click)="setSchedule()"
              class='save-button'>
              {{ 'form-actions.save' | translate }}
            </button>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          </div>
        </ng-container>
      </ng-container>
      <p class="info-text">
        <mat-divider></mat-divider>
        <ui-divider></ui-divider>
        {{ 'drivePrivateScheduler.info-text' | translate }}
      </p>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!carDrivePrivateScheduler?.active">
  <div class="padded-content">

    <p>{{'drivePrivateScheduler.not-set' | translate }}</p>

    <ui-divider></ui-divider>
    <div class="row start">
      <button mat-button
        mat-raised-button
        color="primary"
        (click)="enableCarDrivePrivateScheduler(true)"
        class='save-button'>
        {{ 'drivePrivateScheduler.enable' | translate }}
      </button>
    </div>
  </div>
</ng-container>
